<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Panel główny</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="isCustomerActive()">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Nowe zgłoszenia</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newSignals.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newSignals.selected"
                                    :headers="newSignals.headers"
                                    :items="newSignals.signals"
                                    :search="newSignals.search"
                                    item-key="identifier"
                                    :loading="loading"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage"
                                    :hide-default-footer="false"
                                    :server-items-length="totalItemsNew"
                                    @update:page="handlePageUpdateNew"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.icon`]="{ item }">
                              <v-icon v-if="item.icon">
                                  mdi-paperclip
                              </v-icon>
                          </template>
                          <template v-slot:[`item.type`]="{ item }">
                              <v-icon v-if="item.type == 2">
                                  mdi-phone
                              </v-icon>
                              <v-icon v-if="item.type == 3">
                                  mdi-email-outline
                              </v-icon>
                          </template>
                          <template v-slot:[`item.show`]="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="showSignal(item)" v-on="on">
                                          <v-icon color="green">mdi-open-in-new</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>POKAŻ</span>
                              </v-tooltip>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

          <v-row v-if="isCustomerActive()">
              <v-col cols="12">
                  <v-card class="employee-list mb-1">
                      <v-card-title class="pa-6 pb-3">
                          <p>Odnotowano nowe wiadomości w otwartych zgłoszeniach</p>
                          <v-spacer></v-spacer>
                          <v-text-field v-model="newMessages.search"
                                        append-icon="mdi-magnify"
                                        label="Szukaj"
                                        clearable
                                        single-line
                                        hide-details></v-text-field>
                      </v-card-title>
                      <v-data-table v-model="newMessages.selected"
                                    :headers="newMessages.headers"
                                    :items="newMessages.signals"
                                    :search="newMessages.search"
                                    item-key="identifier"
                                    :loading="loading2"
                                    loading-text="Ładowanie... Proszę czekać"
                                    :items-per-page.sync="itemsPerPage2"
                                    :hide-default-footer="false"
                                    :server-items-length="totalItemsMess"
                                    @update:page="handlePageUpdateMess"
                                    :footer-props="{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }">
                          <template v-slot:[`item.actions`]="{ item }">
                              <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                      <v-btn text @click="showSignal(item)" v-on="on">
                                          <v-icon color="red">mdi-message-processing</v-icon>
                                      </v-btn>
                                  </template>
                                  <span>ODCZYTAJ ZGŁOSZENIE</span>
                              </v-tooltip>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-col>
          </v-row>

      </div>
  </v-container>
</template>

<script>
    import Service from '@/services/Service'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
  name: 'New',
  data() {
    return {
      newSignals: [],
      signals: [],
      newMessages: [],
      signalsDb: [],
      messageDb: [],
      loading: true,
      loading2: true,
      itemsPerPage: 10,
      itemsPerPage2: 10,
      totalItemsMess: 10,
      totalItemsNew: 10,
      pageNumberNew: 1,
      pageNumberMess: 1,
    }
},
components: {
    ErrorSubscription
},
  created(){
        document.title = "Sygnali.pl - Panel administracyjny";
  },
  async mounted() {
      try {
          if (this.isCustomerActive()) {
              this.main = (await Service.main()).data,
                  this.signalsDb = this.main.signals,
                  this.totalItemsNew = this.main.totalItemsSignal,
                  this.totalItemsMess = this.main.totalItemsMessage,
                  this.messageDb = this.main.messages,
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                              width: "1%"
                          },
                          { text: '', value: 'icon', sortable: false, width: "1%" },
                          { text: '', value: 'type', sortable: false, width: "1%" },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Odpowiedzi', value: 'replaysCount' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'show', sortable: false },
                      ],
                      signals: this.signalsDb,
                  },
                  this.loading = false,
                  this.newMessages = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.messageDb,
                  },
                  this.loading2 = false
          }
      } catch (e) {
          if (e.response.status == 401)
              this.$router.push({ name: 'Login' })
      }
  },
  watch: {
        itemsPerPage: {
            async handler() {
                //if (this.itemsPerPage > 20 || this.itemsPerPage == -1) {
                    await this.loadRecords(this.itemsPerPage, 1);
                //}
            },
            deep: true,
        },
        itemsPerPage2: {
            async handler() {
                //if (this.itemsPerPage2 > 20 || this.itemsPerPage2 == -1) {
                    await this.loadRecords(this.itemsPerPage2, 2);
                //}
            },
            deep: true,
        }
    },
  methods: {
      isCustomerActive() {
          return this.$store.state.admin.customerIsActive;
      },
      showSignal(item) {
          this.$router.push({ name: 'Signal', params: { identifier: item.id}})
      },
      async handlePageUpdateNew(newPage) {
          this.pageNumberNew = newPage;
          await this.loadRecords(this.itemsPerPage, 1);
      },
      async handlePageUpdateMess(newPage) {
          this.pageNumberMess = newPage;
          await this.loadRecords(this.itemsPerPage2, 2);
      },
      async loadRecords(count, type) {
          try {

              var page = 1;

              if (type == 1) {
                  this.loading = true;
                  page = this.pageNumberNew;
              }
                  
              if (type == 2) {
                  this.loading2 = true;
                  page = this.pageNumberMess;
              }
                  
              var result = (await Service.getDashboardSignalsForType(count, type, page)).data;
              if (type == 1) {
                  this.signalsDb = result;
                  this.newSignals = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                              width: "1%"
                          },
                          { text: '', value: 'icon', sortable: false, width: "1%" },
                          { text: '', value: 'type', sortable: false, width: "1%" },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Odpowiedzi', value: 'replaysCount' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'show', sortable: false },
                      ],
                      signals: this.signalsDb,
                  }
              }
              if (type == 2) {
                  this.messageDb = result;
                  this.newMessages = {
                      selected: [],
                      search: '',
                      headers: [
                          {
                              text: 'Identyfikator',
                              align: 'start',
                              sortable: false,
                              value: 'identifier',
                          },
                          { text: 'Data wpływu', value: 'datetime' },
                          { text: 'Dni', value: 'days' },
                          { text: 'Etykieta', value: 'title' },
                          { text: 'Kategoria', value: 'category' },
                          { text: '', value: 'actions', sortable: false },
                      ],
                      signals: this.messageDb,
                  }
              }
              this.loading = false;
              this.loading2 = false;
          } catch (e) {
              if (e.response.status == 401)
                  this.$router.push({ name: 'Login' })
          }
      }
  }
}

</script>

<style src="./Basic.scss" lang="scss">
</style>
