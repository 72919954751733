var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Rejestr zgłoszeń")]),_c('ErrorSubscription',{attrs:{"isCustomerActive":_vm.isCustomerActive()}}),(_vm.isCustomerActive())?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v("Zgłoszenia")]),_c('v-spacer'),(_vm.adminCanReports)?_c('v-btn',{attrs:{"text":"","disabled":_vm.newSignals.selected == undefined || _vm.newSignals.selected.length == 0},on:{"click":function($event){return _vm.getAllReports()}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-multiple")]),_c('div',[_vm._v("Pobierz rejestr (.CSV)")])],1):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.newSignals.search),callback:function ($$v) {_vm.$set(_vm.newSignals, "search", $$v)},expression:"newSignals.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.newSignals.headers,"items":_vm.newSignals.signals,"search":_vm.newSignals.search,"show-select":_vm.adminCanReports,"item-key":"id","loading":_vm.loading,"loading-text":"Ładowanie... Proszę czekać","items-per-page":_vm.itemsPerPage,"hide-default-footer":false,"expanded":_vm.expanded,"server-items-length":_vm.totalItems,"show-expand":"","footer-props":{'items-per-page-options': [10, 20, 30, 40, -1], 'items-per-page-text': 'Załaduj wiersze' }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.handlePageUpdate},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.showSignal(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("ZGŁOSZENIE")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',[_vm._v("Informacje dodatkowe:")]),_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v("Etykieta zgłoszenia: "+_vm._s(item.title))]),_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v("Dane sygnalisty: "+_vm._s(item.sygnalistName != null && item.sygnalistName.length > 0 ? item.sygnalistName : 'Brak danych personalnych')+" "+_vm._s(item.sygnalistEmail != null && item.sygnalistEmail.length > 0 ? '(e-mail: '+item.sygnalistEmail+')' : '(brak wskazanego adresu email)')+" "+_vm._s(item.sygnalistPhone != null && item.sygnalistPhone.length > 0 ? '(telefon: '+item.sygnalistPhone+')' : '(brak wskazanego numeru telefonu)'))]),(item.msgDecode != null && item.msgDecode.length > 0 && item.msgDecode != 'Brak danych')?_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v("Przedmiot naruszenia prawa: "+_vm._s(item.msgDecode))]):_vm._e(),(item.msgPersonDecode != null && item.msgPersonDecode.length > 0 && item.msgPersonDecode != 'Brak danych')?_c('p',[_vm._v("Dane osoby, której dotyczy zgłoszenie: "+_vm._s(item.msgPersonDecode))]):_vm._e(),(item.notes !== null && item.notes.length > 0)?_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v("Działania następcze:")]):_vm._e(),(item.notes !== null && item.notes.length > 0)?_c('div',_vm._l((item.notes),function(i){return _c('p',{key:i.id,staticStyle:{"margin-bottom":"5px"}},[_vm._v(" Data: "),_c('b',[_vm._v(_vm._s(_vm.formatDateToDisplay(i.date)))]),_vm._v(" - Administrator: "),_c('b',[_vm._v(_vm._s(i.adminName))]),_vm._v(" - Treść: "),_c('b',[_vm._v(_vm._s(i.text))])])}),0):_vm._e()])]}}],null,true),model:{value:(_vm.newSignals.selected),callback:function ($$v) {_vm.$set(_vm.newSignals, "selected", $$v)},expression:"newSignals.selected"}})],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }